<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사업장 -->
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-select
            :comboItems="useFlagItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="useFlag"
            label="사용여부"
            v-model="searchParam.useFlag"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <div class="card cardcontents">
      <!-- <div class="card-header q-table-header">
        작업허가서
        <div class="card-more">
          <q-btn-group outline >
            <c-btn label="화기 등록" icon="add" @btnClicked="addFire" />
            <c-btn label="일반 등록" icon="add" @btnClicked="addNormal" />
          </q-btn-group>
        </div>
      </div> -->
      <div class="card-body qtable-card-body">
        <q-table
          grid
          class="qtable-card"
          title=""
          :data="grid.data"
          :columns="grid.columns"
          hide-header
          hide-bottom
          :rows-per-page-options="[0]"
          virtual-scroll
        >
          <template v-slot:top-right>
          </template>
          <template v-slot:item="props">
            <q-card class="mobileTableCardLayer col-12 row">
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3" @click="linkClick(props.row)">
                <q-card-section class="grid-card-etc">
                  <div :class="['text-grid-etc', 'txt-box-grid text-primary-box']">
                    {{ props.row.mdmChemMaterialId }}
                  </div>
                  <div :class="['text-grid-etc', 'txt-box-grid text-accent-box']">
                    {{ props.row.vendorName }}
                  </div>
                </q-card-section>
                <q-card-section>
                  <div class="text-grid-title q-mt-sm q-mb-xs">{{ props.row.materialName }}</div>
                </q-card-section>
              </div>
              <div class="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                <c-upload 
                  label="내부 MSDS"
                  :attachInfo="{
                    taskClassCd: 'CHEM_MSDS',
                    taskKey: props.row.mdmChemMaterialId,
                  }"
                  :editable="false"
                />
              </div>
              <div class="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                <c-upload 
                  label="업체 MSDS"
                  :attachInfo="{
                    taskClassCd: 'CHEM_MSDS_VENDOR',
                    taskKey: props.row.mdmChemMaterialId,
                  }"
                  :editable="false"
                />
              </div>
            </q-card>
          </template>
        </q-table>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3" v-if="grid.data.length === 0">
          <q-card class="mobileTableCardLayer">
            <q-card-section class="text-center">
              <q-card-section class="q-pt-xs">
                <div class="text-grid-title q-mt-sm q-mb-xs">데이터가 존재하지 않습니다.</div>
              </q-card-section>
            </q-card-section>
          </q-card>
        </div>
      </div>
    </div>

    <!-- <c-table
      ref="table"
      title="화학자재 별 MSDS 목록"
      tableId="riskHazrd01"
      :editable="editable"
      :columns="grid.columns"
      :data="grid.data"
      :merge="grid.merge"
      @linkClick="linkClick"
    >
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn label="검색" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
      <template v-slot:customArea="{ props, col }">
        <template v-if="col.name === 'warning'">
          <q-btn 
            dense
            outline 
            icon="download"
            color="blue-9"
            style="font-weight:700"
            @click="linkClick(props.row, col)" />
        </template>
        <template v-if="col.name === 'msds'">
          <q-btn 
            dense
            outline 
            :icon="props.row.materialKnowhowId ? 'open_in_new' : 'add'"
            color="blue-9"
            style="font-weight:700"
            @click="linkClick(props.row, col)" />
        </template>
      </template>
    </c-table> -->
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'chem-manage',
  data() {
    return {
      searchParam: {
        plantCd: null,
        useFlag: 'Y',
      },
      useFlagItems: [
        { code: 'Y', codeName: '사용' },
        { code: 'N', codeName: '미사용' },
      ],
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      grid: {
        merge: [
          { index: 0, colName: 'mdmChemMaterialId' },
          { index: 1, colName: 'mdmChemMaterialId' },
          { index: 2, colName: 'mdmChemMaterialId' },
          { index: 3, colName: 'mdmChemMaterialId' },
          { index: 4, colName: 'mdmChemMaterialId' },
          { index: 5, colName: 'mdmChemMaterialId' },
          { index: 8, colName: 'mdmChemMaterialId' },
          { index: 9, colName: 'mdmChemMaterialId' },
        ],
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장명',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'mdmChemMaterialId',
            field: 'mdmChemMaterialId',
            label: '화학자재 번호',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'materialName',
            field: 'materialName',
            label: '화학자재명',
            align: 'left',
            type: 'link',
            style: 'width:250px',
            sortable: true,
          },
          {
            name: 'vendorName',
            field: 'vendorName',
            label: '제조업체',
            align: 'center',
            style: 'width:150px',
            sortable: true,
          },
          {
            name: 'warning',
            field: 'warning',
            label: '경고표지',
            align: 'center',
            style: 'width:90px',
            sortable: true,
            type: 'custom'
          },
          {
            name: 'msds',
            field: 'msds',
            label: 'MSDS<br/>요약본',
            align: 'center',
            style: 'width:90px',
            sortable: true,
            type: 'custom'
          },
          {
            name: 'chemNmEn',
            field: 'chemNmEn',
            label: '구성물질',
            align: 'center',
            style: 'width:200px',
            sortable: true,
          },
          {
            name: 'casNo',
            field: 'casNo',
            label: 'CAS NO.',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'col7',
            field: 'col7',
            label: '내부 MSDS',
            align: 'center',
            style: 'width:200px',
            type: 'attach',
            taskClassCd: 'CHEM_MSDS',
            keyText: 'mdmChemMaterialId',
            sortable: false,
          },
          {
            name: 'col8',
            field: 'col8',
            label: '업체 MSDS',
            align: 'center',
            style: 'width:200px',
            type: 'attach',
            taskClassCd: 'CHEM_MSDS_VENDOR',
            keyText: 'mdmChemMaterialId',
            sortable: false,
          },
        ],
        data: [],
      },
      editable: true,
      headerUrl: '',
      listUrl: '',
      printUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.mdm.mam.chem.msds.url;
      this.printUrl = selectConfig.mdm.mam.chem.print.url;
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = this.$_.uniqBy(_result.data, item => {
          return item.mdmChemMaterialId
        });
      },);
    },
    linkClick(row) {
        // MSDS 요약본 
        this.popupOptions.title = 'MSDS 요약본 상세';
        this.popupOptions.param = {
          mdmChemMaterialId: row ? row.mdmChemMaterialId : '',
        };
        this.popupOptions.target = () => import(`${'@/pages/chm/msds/chemProcessKnowhowDetailAekyung.vue'}`);
        this.popupOptions.visible = true;
        this.popupOptions.isFull = true;
        this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>
